import React from 'react'

// 加载组价

const MonitorLibraryMgrContainer = Loader.loadBusinessComponent('MonitorLibrary', 'MonitorLibraryMgrContainer')

const KeyPersonMonitorLibMgr = () => {
  const libTypeInfo = {
    libType: 5,
    libLabel: '隔离人员库',
    libPeople: '隔离人员',
    actionName: 'epidemicPersonTrackLibraryManage',
    moduleName: 'epidemicPersonTrackLibrary'
  }
  return <MonitorLibraryMgrContainer libTypeInfo={libTypeInfo} />
}

export default KeyPersonMonitorLibMgr
